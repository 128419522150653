import React, { useState } from "react"
import Layout from "../../containers/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, setLocale } from "yup";
import {Link, navigate, graphql} from "gatsby";
import {GoLinkExternal} from "react-icons/go";

import * as css from "../../sass/contact.module.scss"
import Seo from "../../containers/Seo";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactPage = () => {
  setLocale({
    string: {
      email: 'メールアドレスの形式が正しくありません',
      required: '必ず入力してください'
    }
  });
  const schema = object({
    name: string().required(),
    email: string().email().required(),
    content: string().required()
  });

  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data, e) => {
    setLoading(true);
    fetch("/", {
      method: "POST",
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
      body: encode({"form-name": "Contact", ...data})
    })
      .then(() => {
        navigate('/contact/complete');
        setLoading(false);
      })
      .catch(error => {
        //this.setState({error, loading: false});
      });

    e.preventDefault();
  };

  return (
    <Layout>

      <section className={css.contact}>
        <h1>お問い合わせ</h1>

        <p>
          お問い合わせいただき誠にありがとうございます。<br />
          すべての項目にご記入の上「送信する」をタップしてください。<br />
          送信いただいた情報はお問い合わせの回答のみに使用し、それ以外の目的では利用いたしません。<br />
          詳しくは<Link to="/privacy">プライバシーポリシー <GoLinkExternal /></Link>をご覧ください。
        </p>

        <form onSubmit={handleSubmit(onSubmit)} data-netlify="true">
          <input type="hidden" name="form-name" value="Contact" />
          <div className={`${css.formRow} ${errors.name ? css.hasError : ''}`}>
            <label htmlFor="text">お名前</label>
            <input id="text" type="text" className={css.formControl} placeholder="氏名を入力してください" {...register("name", {required: true})} />
            {errors.name && (
              <div className={css.formError}>{errors.name?.message}</div>
            )}
          </div>

          <div className={`${css.formRow} ${errors.email ? css.hasError : ''}`}>
            <label htmlFor="email">メールアドレス</label>
            <input id="email" type="email" className={css.formControl} placeholder="info@noop.co.jp" {...register("email", {required: true})} />
            {errors.email && (
              <div className={css.formError}>{errors.email?.message}</div>
            )}
          </div>

          <div className={css.formRow}>
            <label htmlFor="content">お問い合わせ内容</label>
            <textarea id="content" className={css.formControl} rows={8} placeholder="お問い合わせ内容を入力してください" {...register("content", {required: true})} />
          </div>

          <div className={css.formAction}>
            <button type="submit" className={css.btn} disabled={isLoading}>
              {isLoading ? '送信しています' : '送信する'}
            </button>
          </div>
        </form>
      </section>

    </Layout>
  );
}

export default ContactPage;

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
        author
        twitter
        siteUrl
      }
    }
  }
`

export const Head = ({data}) => (
  <Seo data={data}>
    <title id="title">お問い合わせ | {data.site.siteMetadata.title}</title>
  </Seo>
);
